.characteristic-title{
  margin-bottom: 15px;
}
.characteristic-button{
  color: brown;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  border: 1px solid brown;
  text-transform: uppercase;
}
.characteristic-table{
  display: none;
}
hr.hide{
  display: none;
}
.block-quote {
  border-left: 3px solid #ff0048;
  padding-left: 14px;
  background: #dcdcdc;
  padding: 5px 5px 5px 14px;
  margin: 20px 0;
}
.gift {
  border: 1px solid #ff0048;
  padding: 5px;
  margin: 20px 0;
  font-weight: bold;
}
.remodal .catalog-item--price{
      color: black;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}
.remodal .catalog-item--price .oldprice {
  text-decoration: line-through;
  color: #9b9b9b;
  font-size: 17px;
  display: inline-block;
}
.consultation{
  position: fixed;
  right: 100px;
  bottom: 0;
  border-bottom: 3px solid #7ab51d;
  padding: 8px 17px;
  background: #374247;
  color: #fff !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1;
  font-size: 15px;
  cursor: pointer;
}
.consultation:hover{
  border-bottom: 0;
}
.remodal-consultation{
  max-width: 700px;
}
.action{
  width: 80px;
  position: absolute;
  left: 15px;
  top: 0;
  z-index: 2;
}
.remodal-close{
  color: #a52a2a;
  top: 10px;
  right: 10px;
  left: auto;
}
.remodal-close:before {
  font-size: 35px;
}
footer iframe{
  width: 100%;
}
header .contacts.fixed {
  z-index: 3;
}


header .contacts a, header h1, header h2{
  text-shadow: 1px 1px 3px #000;
}
.steps{
  text-align: center;
  padding-top: 50px;
}
.steps .image{
  margin-top: 20px;
}
.steps .title{
  padding: 22px 0 3px 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  color: #ec230f;
}
.steps .section-title{
  font-family: 'Russo One', sans-serif;
  text-align: center;
  font-size: 50px;
  margin-top: 20px;
}
.phone:hover{
  text-decoration: none;
}
.phone span{
  font-size: 30px;
}
.main-info{
  padding: 30px;
  z-index: 1;
  font-size: 20px;
  border: 2px solid #ffffff;
  margin: 0 auto;
  display: block;
}
.main-info li{
      list-style: circle;
}
header .contacts.fixed .phone img{
  height: 32px;
}
header .contacts.fixed .phone{
  font-size: 19;
}
header .contacts.fixed .phone span{
  font-size: 16px;
}
header .contacts.fixed{
  padding: 0;
}
header .contacts.fixed img{
  height: 83px;
}
header .contacts.fixed .phones{
 padding: 5px 10px 8px;
}
.catalog-menu{
  margin-bottom: 30px;
}
.catalog-menu .row{
  justify-content: center;
}
.active .catalog-menu--item{
  border-bottom: 2px solid red;
}
.catalog-menu--item{
  margin-bottom: 15px;
  text-align: center;
}
.catalog-menu--item a{
  color: #000;
  font-size: 20px;
  text-decoration: none;
}
.catalog-menu--item{
  border-bottom: 2px solid transparent;
}
.catalog-menu--item .price{
  font-size: 15px;
}
.catalog-menu--item:hover{
  border-bottom: 2px solid #787775;
}
.active .catalog-menu--item:hover{
  border-bottom: 2px solid red;
}
.header-links{
  z-index: 2;
  margin-top: 30px;
}
.header-link a{
  color: #fff;
  display: inline-block;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #efefef;
  font-weight: bold;
  text-decoration: none;
}
.header-link div:first-child{
  text-align: right;
}
.header-link a.active{
  border-color: red;
}
.catalog h2{
  text-align: center;
}

.params-section__row{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  font-size: 13px;
}
.params-section__row div:first-child{
  font-weight: bold;
  text-align: left;
}
.pagination li{
  border: 1px solid #efefef;
  padding: 3px 10px;
      margin: 10px 3px;
}
.pagination li a{
  color: #000;
}
.pagination li.active{
  border-color: red;
}
.pagination{
  flex-wrap: wrap;
}
.footer-contacts{
  font-weight: bold;
  background: rgb(88, 88, 88);
  padding: 30px;
  color: #fff;
}
.footer-contacts h2{
  text-align: center;
  margin-bottom: 30px;
}
.contacts-address{
  font-weight: 100;
      font-size: 17px;
  text-align: center;
}
.contacts-phone{
      text-align: center;
  margin: 10px 0;
}
.contacts-phone a{
      color: #fff;
  font-size: 25px;
}
.contacts-office-hours{
  text-align: center;
  font-size: 14px;
  font-weight: 100;
}
@media (max-width:666px){
  .contacts img{
      height: 50px;
  }
  .contacts .phones img{
      width: auto;
  }
}
@media (max-width: 575px){
  a.consultation{
      display: none;
  }
      section.catalog .catalog-item--image .back{
      display: block;
  }    
  section.catalog .catalog-item--image .front{
      display: block;
  }
  section.catalog .catalog-item--order button{
      opacity: 1;
  }
  section.catalog .catalog-item--image:hover .front {
      display: block;
  }
  .phone{
      font-size: 19;
  }
  .phone img{
      height: 16px;
  }
  .phone span{
      font-size: 16px;
  }
  .contacts-address{
          border-bottom: 1px solid #efefef;
  padding-bottom: 20px;
  }
  .contacts-office-hours{
      margin-top: 20px;
  }
}
@media (max-width: 480px){
  header h1 {
      font-size: 19px;
  }
}
@media (max-width: 400px){
 header .contacts.fixed img{
  height: 40px;
  } 
}