$red: #ff0000;
$duration: .4s;
$green: #91cebe;
$white: #fff;

.message {
  &--danger {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border: 1px solid;
  }
  &--red {
    color: $red;
    border-color: $red;
  }
}

.v-form {
  &-characteristic {
    margin-bottom: 30px;
  }
  &-modal {
    &-image {
      max-width: 100%;
    }
    &-dialog {
      max-width: 1140px;
    }
    &-content {
      padding: 35px;
    }
    &-close {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #efefef;
    transition: border-color $duration;
    &--error {
      border-color: $red;
    }
  }
  &-item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-label {
    margin-bottom: 8px;
    font-size: 13px;
    .colored {
      color: $red;
    }
  }
  &-submit {
    text-transform: uppercase;
    &:focus {
      outline: none;
    }
  }
  &-text {
    font-size: 16px;
    margin-bottom: 30px;
    &--success {
      padding: 15px;
      border: 1px solid $green;
    }
  }
  &-footer {
    font-size: 12px;
    transition: opacity $duration, color $duration;
    &-link {
      color: $green;
      &:hover {
        color: darken($green, 10%);
      }
      &:active {
        opacity: .8;
      }
    }
  }
}

.btn {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  transition: background-color $duration, color $duration, opacity $duration;
  &:focus, &:focus:active {
    outline: none;
  }
  &:ative {
    opacity: .8;
  }
  &--green {
    color: $white;
    background-color: $green;
    &:hover {
      color: $white;
      background-color: darken($green, 10%);
    }
    &:focus {
      color: $white;
    }
  }
  &--red {
    color: $white;
    background-color: $red;
    &:hover {
      color: $white;
      background-color: darken($red, 10%);
    }
    &:focus {
      color: $white;
    }
  }
  &--full {
    width: 100%;
  }
}

[v-cloak] {
  display: block;
  padding: 50px 0;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  & > div {
    display: none;
  }
}

.whatsapp-wiget {
  right: 50px;
  position: fixed;
  bottom: 150px;
  padding-right: 0;
  z-index: 2;
  @media (max-width: 414px) {
    right: 25px;
    bottom: 110px;
  }
  &-link {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.05;
    align-items: center;
    transition: color 0.4s;
    border-radius: 50%;
    width: 80px;
    justify-content: center;
    height: 80px;
    background-color: #01e675;
    @media (max-width: 414px) {
      width: 72px;
      height: 72px;
    }
  }
  &-icon {
    width: 50px;
    height: 50px;
    margin-right: 0;
    fill: white;
    @media (max-width: 414px) {
      width: 40px;
      height: 40px;
    }
  }
}