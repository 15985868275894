@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:400,700|Russo+One');

input, textarea {
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	cursor: pointer; }

	[data--black__overlay] {
		position: relative;
	}

	[data--black__overlay]:before {
		position: absolute;
		content: '';
			background: #000;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}
		[data--black__overlay="1"]:before {
			opacity: 0.1;
		}

		[data--gray__overlay] {
			position: relative;
		}

		[data--gray__overlay]:before {
			position: absolute;
			content: '';
				background: #fafafa;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 1;
			}
			[data--gray__overlay="9"]:before {
				opacity: 0.9;
			}
			[data--gray__overlay="9.5"]:before {
				opacity: 0.95;
			}


			[data--black__overlay="2"]:before {
				opacity: 0.2;
			}
			[data--black__overlay="3"]:before {
				opacity: 0.3;
			}
			[data--black__overlay="4"]:before {
				opacity: 0.4;
			}
			[data--black__overlay="5"]:before {
				opacity: 0.5;
			}
			[data--black__overlay="6"]:before {
				opacity: 0.6;
			}
			[data--black__overlay="7"]:before {
				opacity: 0.7;
			}
			[data--black__overlay="8"]:before {
				opacity: 0.8;
			}
			[data--black__overlay="9"]:before {
				opacity: 0.9;
			}
			[data--black__overlay="10"]:before {
				opacity: 1;
			}
			[data--black__overlay="0"]:before {
				opacity: 0;
			}

			[data--black__overlay="0"] > div{
				z-index: 5;
				color: #fff;
			}

			.block{
				font-family: 'Roboto', sans-serif;
				font-family: 'Roboto Slab', serif;
				font-family: 'Russo One', sans-serif;
			}
			body{
				font-family: 'Roboto', sans-serif;
			}
			header{
				background: url(../images/header-bg.jpg);
				height: 100vh;
				background-position: bottom center;
				background-size: cover;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				color: #fff;
				.contacts{
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					justify-content: space-between;
					align-items: center;
					top: 0;
					position: absolute;
					text-align: right;
					width: 100%;
					padding: 30px 40px 0 40px;
					z-index: 2;
					-webkit-transition: all 0.6s;
					-o-transition: all 0.6s;
					transition: all 0.6s;
					@media (max-width: 700px) {
							padding: 10px;
						}
					&.fixed{
						position: fixed;
						background: rgba(0,0,0,.5);
						padding: 10px 30px 10px 10px;
						img{
							height: 50px;
						}
						a{
							font-size: 23px;
						}
					}

					a{
						color: #fff;
						font-size: 40px;	
						font-family: 'Roboto Slab', serif;

						@media (max-width: 1100px) {
							font-size: 30px;
						}
						@media (max-width: 700px) {
							font-size: 25px;
						}
						@media (max-width: 480px) {
							font-size: 20px;
						}
					}
				}
				h1{
					z-index: 2;
					font-size: 90px;
					font-family: 'Russo One', sans-serif;
					@media (max-width: 1100px) {
						font-size: 60px;
					}
					@media (max-width: 700px) {
						font-size: 40px;
					}
					@media (max-width: 480px) {
						font-size: 25px;
					}
				}
				h2{
					z-index: 2;
					font-family: 'Roboto Slab', serif;
					font-size: 40px;
					@media (max-width: 1100px) {
						font-size: 40px;
					}
					@media (max-width: 700px) {
						font-size: 30px;
					}
					@media (max-width: 480px) {
						font-size: 20px;
					}
				}
			}
			section{
				&.catalog{
					padding: 80px 0;
					h2{
						font-family: 'Russo One', sans-serif;
						font-size: 40px;
						margin: 0 auto 30px;
					}
					.catalog-item{
						cursor: pointer;
						margin-bottom: 30px;
						&--image{
							img{
								width: 100%;
							}
							.back{
								display: none;
							}
							&:hover{
								.front{
									display: none;
								}
								.back{
									display: block;
								}
							}
						}
						&--title{
							margin-top: 20px;
							font-weight: bold;
							text-align: center;
							color: rgb(246, 104, 117);
							font-size: 20px;
						}
						&--price{
							color: black;
							font-weight: bold;
							text-align: center;
							font-size: 20px;
							margin: 20px 0;
							.oldprice{
								text-decoration: line-through;
								color: rgb(155, 155, 155);
								font-size: 17px;
								display: inline-block;
							}
						}
						&--order{
							text-align: center;
							button{
								font-family: 'Roboto Slab', serif;
								border-radius: 25px;
								padding: 10px 17px;
								color: rgb(255, 255, 255);
								border: 1px solid;
								background-color: rgb(221, 61, 75);
								border-color: rgb(221, 61, 75);
								opacity: 0;
							}
						}
						&:hover{
							.catalog-item--order{
								button{
									opacity: 1;
								}
							}
						}
					}
				}
			}
			.remodal{
				.title{
					font-size: 25px;
					margin-bottom: 15px;
				}
				img{
					width: 100%;
					margin-bottom: 20px;
				}
				.remodal-desc{
					display: -webkit-flex;
						display: -moz-flex;
						display: -ms-flex;
						display: -o-flex;
						display: flex;
							flex-direction: column;
							justify-content: space-between;
				}
				form{
					input{
						width: 100%;
						margin-bottom: 10px;
						padding: 3px 10px;

					}
					button{
						width: 100%;
						padding: 3px 10px;
						border: 1px solid brown;
						background: brown;
						color: #fff;
					}
				}
				table{
					font-size: 12px;
					text-align: left;
					&.table{
						tr{
							td{
								padding: 3px;
								&:nth-child(2){
									font-weight: bold;
									padding-left: 20px;
								}
							}
						}
					}
				}
			}

			@media only screen and (min-width: 641px){
			.remodal {
				max-width: 1140px;
			}
			}
